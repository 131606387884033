.admin {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;

	overflow-x: auto;
	-webkit-overflow-scrolling: touch;

	.logo-container {
		height: 10% !important;
		.logo {
			height: 24px;
		}
	}

	.menu {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-bottom: 8px;
		height: 48px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

		.menu-button {
			width: 300px;
			height: 100%;
			border-radius: 3px;
			color: #515151;
			text-transform: uppercase;
			margin: 0px;

			&.selected {
				border-bottom: 2px solid orange;
			}
		}
	}

	.main-container {
		height: calc(100% - 145px);
		overflow-y: auto;
		display: flex;
	}
}
