@import "material-icons";
@import "../node_modules/react-md/src/scss/react-md";
@include react-md-everything;

html {
    height: 100%;
}

body {
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: Helvetica, Arial, sans-serif;
    text-rendering: optimizelegibility;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fbfbfb;
}

#root {
    height: 100%;
    min-height: 100%;

    display: flex;

    & > * {
        flex-grow: 1;
    }

    .logo-container {
        width: 100%;
        height: 17.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        -webkit-transition: height 1000ms ease-in-out;
        -moz-transition: height 1000ms ease-in-out;
        -o-transition: height 1000ms ease-in-out;
        transition: height 1000ms ease-in-out;

        .logo {
            height: 30px;
            background-image: url("./resources/icons/Logo.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            cursor: pointer;
            opacity: 0.6;

            -webkit-transition: opacity 400ms ease-in-out;
            -moz-transition: opacity 400ms ease-in-out;
            -o-transition: opacity 400ms ease-in-out;
            transition: opacity 400ms ease-in-out;
        }

        &.hidden {
            height: 0px;
            .logo {
                opacity: 0;
            }
        }
    }

    .all-rights-reserved {
        font-size: 10px;
        font-family: "AppleSymbols";
        position: absolute;
        right: 24px;
        bottom: 12px;
        opacity: 0.5;
    }

    @media only screen and (max-width: 768px) and (orientation: portrait) {
        .logo-container {
            .logo {
                height: 24px;
            }
        }
    }
}
