.collections {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 24px 64px;

	.add-button {
		height: 48px;
		width: 48px;
		position: absolute;
		right: 48px;
		bottom: 5%;
		color: white;
		background-color: black;
		border-radius: 50%;
	}

	.new-collection-dialog {
		.md-dialog--centered {
			width: 360px;

			.md-text--theme-secondary {
				color: #000000;
			}
		}
	}

	.progress-bar {
		position: absolute;
		left: 50%;
		bottom: 50%;
		z-index: 1;
	}

	&.pending {
		filter: blur(1px) brightness(95%);
	}
}
