.cover-config {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 40px;
	width: 100%;

	.progress-bar {
		position: absolute;
		left: 50%;
		bottom: 50%;
		z-index: 1;
	}

	.preview {
		display: flex;
		flex-direction: column;
		padding: 8px;
		width: 35%;
		border: 1px solid #e5e5e5;

		span {
			color: #515151;
			font-size: 9px;
			font-style: italic;
		}
	}

	.config-fields {
		display: flex;
		flex-direction: column;

		.md-cell {
			width: 300px;
			border-bottom: 1px solid #e5e5e5;
		}

		.md-file-input-container {
			margin-top: 32px;
		}
	}
}
