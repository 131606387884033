.instagram-logo {
	height: 32px;
	position: absolute;
	left: 10%;
	top: 88.75%;
	cursor: pointer;
	opacity: 1;
	-webkit-transition: opacity 400ms ease-in-out;
	-moz-transition: opacity 400ms ease-in-out;
	-o-transition: opacity 400ms ease-in-out;
	transition: opacity 400ms ease-in-out;

	&.hidden {
		opacity: 0;
	}

	@media only screen and (max-width: 568px) and (orientation: landscape) {
		height: 24px;
		top: 86%;
	}

	@media only screen and (max-width: 468px) {
		height: 28px;
		bottom: 12px;
		left: 16px;
		top: auto;
	}
}
