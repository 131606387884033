.progress-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  .text {
    color: #424242;
    font-size: 16px;
    font-weight: 300;
    margin-top: 12px;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 76px;
    height: 76px;
    margin: 6px;
    border: 6px solid #fcf;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(195, 195, 195, 1) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
