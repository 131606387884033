.contact {
	display: flex;
	flex-flow: column;
	align-items: center;
	background-color: #ffffff;

	.contact-container {
		width: 40%;
		height: 65%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		.contact-form {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;

			.md-text-field-container {
				width: 50%;

				.md-floating-label {
					text-transform: uppercase;
					color: #858585;
					font-size: 13px;
					font-family: "Helvetica";
				}
			}
		}

		.contact-label-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			.contact-label {
				margin-top: 4px;
				color: #858585;
			}
		}

		.email-status {
			margin-top: 8px;
			padding: 8px;
			color: #858585;
			font-weight: bold;
		}
	}

	@media only screen and (max-width: 1360px) {
		.contact-container {
			width: 75%;
		}
	}

	@media only screen and (max-width: 768px) {
		.contact-container {
			width: 100%;
		}
	}

	@media only screen and (max-width: 420px) {
		.contact-container {
			.contact-form {
				.md-text-field-container {
					width: 80%;
				}
			}
		}
	}
}
