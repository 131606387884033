.gallery {
	height: 65%;
	width: 100%;
	display: flex;
	flex-direction: column;

	-webkit-transition: height 1s ease;
	-moz-transition: height 1s ease;
	-o-transition: height 1s ease;
	transition: height 1s ease;

	will-change: height;

	&.fullScreen {
		height: 90%;

		.navigation-container {
			.image-container {
				width: 90% !important;

				.image:hover {
					cursor: zoom-out;
				}
			}
			.click-area {
				height: 90%;
			}
		}
	}

	.navigation-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 100%;

		.left-click-area {
			left: 0;
		}

		.right-click-area {
			right: 0;
		}

		.click-area {
			position: absolute;
			height: 65%;
			width: 40%;
			z-index: 50;
		}

		&:hover {
			.image-container {
				.arrow {
					opacity: 0.5;
				}
			}
		}

		.image-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 80%;
			-webkit-transition: opacity 400ms ease-in-out;
			-moz-transition: opacity 400ms ease-in-out;
			-o-transition: opacity 400ms ease-in-out;
			transition: opacity 400ms ease-in-out;
			position: relative;

			.arrow {
				height: 36px;
				opacity: 0;
				transition: opacity 100ms ease-in-out;
			}

			.image {
				display: block;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;

				&:hover {
					cursor: zoom-in;
				}
			}

			.image-title {
				display: block;
				position: absolute;
				font-family: "Helvetica";
				font-size: 7px;
				color: #343434;
				opacity: 0.7;

				-webkit-transition: opacity 400ms ease-in;
				-moz-transition: opacity 400ms ease-in;
				-o-transition: opacity 400ms ease-in;
				transition: opacity 400ms ease-in;

				&.hidden {
					-webkit-transition: opacity 0ms ease-in;
					-moz-transition: opacity 0ms ease-in;
					-o-transition: opacity 0ms ease-in;
					transition: opacity 0ms ease-in;

					opacity: 0 !important;
				}
			}

			&.hidden {
				position: absolute;
				top: -9999px;
				left: -9999px;
				opacity: 0;
			}
		}
	}

	@media (hover: none) {
		.navigation-container {
			.image-container {
				.arrow {
					display: none;
				}
			}
		}
	}
}
