.navigation-bar {
  display: flex;
  flex-flow: row;
  justify-content: center;
  height: 17.5%;

  -webkit-transition: height 1000ms ease-in-out;
  -moz-transition: height 1000ms ease-in-out;
  -o-transition: height 1000ms ease-in-out;
  transition: height 1000ms ease-in-out;

  &.hidden {
    height: 0px;

    .navigation-button {
      opacity: 0;

      &:hover {
        cursor: default;
      }
    }
  }

  .navigation-button {
    padding: 16px;
    border-radius: 10px;
    margin-right: 4px;
    margin-left: 4px;
    -webkit-transition: opacity 400ms ease-in-out;
    -moz-transition: opacity 400ms ease-in-out;
    -o-transition: opacity 400ms ease-in-out;
    transition: opacity 400ms ease-in-out;
    background: none;
    border: 0px;

    font-family: "AppleSymbols";
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #858585;
    opacity: 0.7;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 0;
    }

    &.selected {
      text-decoration: underline; //half size line / animation on click
    }
  }

  @media only screen and (max-width: 568px) and (orientation: landscape) {
    .navigation-button {
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  @media only screen and (max-width: 468px) {
    .navigation-button {
      padding: 8px;
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  @media only screen and (max-width: 320px) {
  }
}
