.image-list {
	width: 85%;
	display: flex;

	.preview-column {
		text-align: center;

		.gallery-image {
			margin: 4px;
			height: 100px;
		}
	}

	.material-icons {
		cursor: pointer;
	}
}
