.about {
	display: flex;
	flex-flow: column;
	align-items: center;
	background-color: #ffffff;

	.text-container {
		height: 65%;
		width: 40%;
		text-align: justify;
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;

		p {
			font-family: Helvetica;
			white-space: pre-wrap;
			opacity: 0.7;
		}

		.portrait {
			max-height: 200px;
			float: left;
			margin-right: 24px;
			margin-bottom: 16px;
			filter: drop-shadow(3px 3px 3px #aaaaaa);
		}
	}

	@media only screen and (max-width: 1360px) {
		.text-container {
			width: 60%;
		}
	}

	@media only screen and (max-width: 660px) {
		.text-container {
			width: 75%;

			.portrait {
				float: none;
				margin-bottom: 4px;
			}
		}
	}
}
