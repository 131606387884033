.collection-card {
	border: 1px solid #b1b1b1;
	border-radius: 4px;
	box-shadow: 5px 5px 5px 0px rgba(196, 196, 196, 0.45);

	display: flex;
	flex-flow: column;
	padding: 16px;

	min-height: 230px;
	margin-bottom: 24px;

	.progress-bar {
		position: initial !important;
		left: auto !important;
		bottom: auto !important;
	}

	.card-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		border-bottom: 2px solid #b1b1b1;

		.material-icons {
			margin-left: 16px;
			color: #515151;
			cursor: pointer;
		}

		.md-switch-track--on {
			background: rgba(0, 130, 0, 0.5);

			.md-switch-thumb--on {
				background: #008a00;
			}
		}

		.order {
			width: 100px;
		}
	}

	.card-body {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.placeholder {
			width: 120px;
			height: 140px;
			border: 2px dotted #b1b1b1;
			margin-right: 8px;
			margin-left: 8px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&:before {
				content: "Drop here";
				color: #b1b1b1;
			}
		}

		.collection-images {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			margin-top: 16px;

			.thumbnail {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 12px;
				position: relative;

				.collection-image {
					height: 140px;
				}

				.cancel {
					position: absolute;
					right: -15px;
					top: -15px;
					opacity: 0;
					cursor: pointer;
					color: #515151;
					transition: all 0.5s ease;
				}

				.image-name {
					font-size: 12px;
				}

				&:hover {
					cursor: move;

					.cancel {
						opacity: 1;
					}
				}
			}
		}
	}
}
