.about-config {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	padding: 24px;

	.progress-bar {
		position: absolute;
		left: 50%;
		bottom: 50%;
		z-index: 1;
	}

	.edit-container {
		flex: 1;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		.about-text {
			-webkit-overflow-scrolling: touch;
			width: 100%;
			.md-floating-label {
				font-size: 16px;
			}
		}

		.action-buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 8px;

			.save-button {
				cursor: pointer;
				background-color: #000000;
				color: #ffffff;
				border-radius: 24px;
				height: 32px;

				&:hover {
					background-color: #03a9f4;
				}
			}
		}
	}

	.preview {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 8px;
		border: 1px solid #e5e5e5;

		span {
			color: #515151;
			font-size: 9px;
			font-style: italic;
		}

		.about {
			height: 100%;
			.instagram-logo {
				display: none;
			}
			.navigation-bar {
				display: none;
			}
			.all-rights-reserved {
				display: none;
			}

			.text-container {
				width: 65%;
				height: 85%;
			}
		}
	}
}
