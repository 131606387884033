.cover {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;

  .image-container {
    width: 80%;
    height: 68%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .cover-image {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .logo-container {
    height: 16%;

    .logo {
      height: 40px !important;
    }
  }

  .cookieConsent {
    background: rgba(43, 55, 59, 0.8);

    .learn-more-link {
      color: #ffffff;
      opacity: 0.8;
    }

    div {
      margin: 8px !important;
      font-size: 11px !important;
    }

    button {
      margin: 8px 16px !important;
      margin-left: auto !important;
      background: none !important;
      color: #ffffff !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .logo-container {
      .logo {
        height: 32px !important;
      }
    }

    .cookieConsent {
      button {
        margin-left: auto !important;
      }
    }
  }
}
