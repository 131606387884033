.login {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;

	.logo {
		height: 40px;
	}

	.login-form {
		height: 60%;
		width: 40%;
		box-shadow: 0px 0px 4px 4px rgba(196, 196, 196, 0.45);
		border-radius: 6px;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		.md-text-field-container {
			width: 75%;
			max-width: 300px;
		}

		.login-button {
			width: 75%;
			max-width: 300px;
			margin-top: 32px;
		}
	}
}
